import React, { useState } from 'react';
import './toggle.css';
import { useDispatch } from 'react-redux';

const ToggleButton = ({text,fn,value}) => {
  const dispatch = useDispatch();

  const toggleSwitch = () => {dispatch(fn());};

  return (
    <label className="toggle-container">
      <input type="checkbox" checked={value} onChange={toggleSwitch} />
      <span className="toggle-slider"></span>
      <span className="toggle-label">{text}</span>
    </label>
  );
};

export default ToggleButton;