const current_year = new Date().getFullYear()

export const initialState = {
    valdkonnad: [],
    links: [],
    timeline_range: [],
    nodes: [],
    url:{
        path:undefined,
        params:{}
    },
    selected: undefined,
    departments: {},
    filters: [],
    all_data:undefined,
    language:"ET",

    show_all: false,
    show_map: false,
    show_filters: false,
    menu_open:false,
    search_open:false,
    show_info:false,
    show_card: false,
    only_current:true,

    yearrange: {low: current_year, high: current_year},
    dots_conf: {sorting_order: ["TSP","VKP","HP","TP","SVP","NA"],n_as_dot: 10},
    svg_height: 1000,
    svg_width: 1000,
    duration:500,
}