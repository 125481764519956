import React, {useEffect, useRef} from 'react';
import ReactMarkdown from "react-markdown";
import sectionize from 'remark-sectionize'
import * as Yup from 'yup';
import { groupBy as g, map as m, path, prop as p, compose as C, join, chain, sort, ascend, 
    values, head, addIndex, filter as f } from 'ramda';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import { useLangQuery, usePeopleQuery, useUpdatePersonMutation } from '../../app/api.js';
import { EditableCollapsible } from "../../pages/part";
import { EditableText } from "../../pages/EditableText";
import './../../pages/part.css';
import "./people.css";
import { add_size, scroll_into_view } from '../helpers.js';
import { AppLink } from '../navigation/applink.jsx';
const placeholder = "/uploads/ui/placeholder_borderless.svg"   

export const fullname = x => (x.firstname || "") + " " + (x.lastname || "");
export const People = () => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const openRef = useRef();
    const selected = useSelector(path(["ui","selected"]));
    const {data} = usePeopleQuery();
    const {slug} = useParams();

    useEffect(() => {
        if (openRef.current) setTimeout(scroll_into_view(openRef,180),1000)
    },[openRef,selected])

    const is_not_first_opened = (x,selected) => {
        return selected?.slug !== x?.slug || (selected?.n ? selected.n !== x.n : !x.the_first);
    }

    return data ? C(
        m((xs) => 
            <div key={xs[0].osakond} className="osakond">
                <h2>{xs[0].osakond}</h2>
                <ul className="peoplelist">{m(x => {
                    return (
                    <li key={x.slug} className={is_not_first_opened(x,selected) ? "collapsed" : ""} 
                        ref={!is_not_first_opened(x,selected) ? openRef : undefined}>
                        {is_not_first_opened(x,selected) ? 
                        <AppLink to={`/inimesed/${x.slug}`} className="personlink" selected={x}/> :
                        <AppLink to={`/inimesed`} className="backbutton" selected={undefined}>
                            <img src="/back.svg" alt="back button" className="back"/>
                        </AppLink>}
                        {x?.pilt ? 
                        <img src={add_size(300, x.pilt)} key={x.slug + "-pic"} 
                            title={fullname(x)} alt={fullname(x) + " profiilipilt"} 
                            decoding="async" className="profile_img" loading="lazy"/> :
                        <img src={placeholder} title={fullname(x)} 
                            alt={fullname(x) + " profiilipilt"} decoding="async" 
                            className="profile_img" loading="lazy"/>}
                        <div className="card">
                            <h2 style={{ display: "block" }} key={x.slug + "-name"}>{fullname(x)}</h2>
                            <p style={{ display: "block" }}>{x[language === "EN" ? "job_eng" : "job"]}</p>
                            <a href={"mailto:" + x.email}>{x.email}</a>
                            <p className="osakonnad">{join(", ",m(x => x.Osakonnad[language === "EN" ? "name_eng" : "name"],x.Inimene_Osakond))}</p>
                            <div className="show_projects_people">
                                <AppLink  type="all" to="/tood/" selected={x}>{translation["all_works"]}</AppLink>
                            </div>
                        </div>
                                    
                        <div className="person">
                            {is_not_first_opened(x,selected) ? "" : <>
                                <EditableCollapsible
                                    className={"person"}
                                    slug={slug}
                                    FormElements={({ data }) => <>
                                        <Field name="firstname" className="h1_input" placeholder="Eesnimi"/>
                                        <Field name="lastname" className="h1_input" placeholder="Perenimi"/>
                                        <EditableText className="textArea" name={language === "EN" ? "description_eng" : "description"}/></>}
                                    validations={{
                                        firstname: Yup.string(),
                                        lastname: Yup.string(),
                                        [language === "EN" ? "description_eng" : "description"]: Yup.string()}}
                                    RenderElements={({ data }) => <>  
                                        <ReactMarkdown className={"formattedText"} 
                                            remarkPlugins={[sectionize]} children={data?.[0]?.[language === "EN" ? "description_eng" : "description"]} /></>}
                                    queryFn={usePeopleQuery}
                                    mutationFn={useUpdatePersonMutation}/></>}
                        </div>
                    </li>)}, xs)}
                </ul>
            </div>),   
        sort(ascend(C(p("osakond_order"),head))),
        values,
        g(p("osakond")),
        addIndex(m)((x,n,xs) => ({...x,n ,the_first: xs.map(p("id")).indexOf(x.id) === n})),
        chain(xs => m(x => ({...xs,...x,
            osakond: x.Osakonnad[language === "EN" ? "name_eng" : "name"], 
            osakond_order: x.Osakonnad.order_nr
        }),xs.Inimene_Osakond)),
        f(p("active"))
    )(data) : "";
}