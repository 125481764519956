import React, { useEffect, useRef, useState } from 'react';
import "./news.css";
import * as Yup from 'yup';
import sectionize from 'remark-sectionize'
import { useLangQuery, useNewsQuery, useUpdateNewsMutation } from '../app/api';
import { useLocation, useParams } from 'react-router-dom';
import { compose as C, groupBy as g, toPairs, sort, descend,last, prop as p, head, map as m, path, 
    addIndex, fromPairs, uniq, indexBy,__,split,defaultTo} from 'ramda';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { EditableCollapsible } from './part';
import { Link } from 'react-router-dom';
import { EditableText } from './EditableText';
import { useDispatch, useSelector } from 'react-redux';
import { open_news } from '../app/ui_state';
import ReactMarkdown from 'react-markdown';
import { scroll_into_view } from '../features/helpers';

const url = "https://praxis.us4.list-manage.com/subscribe/post?u=83db137b4bc99ea8531555f74&amp;id=9493250305&amp;f_id=00533eebf0";
const archive = "https://us4.campaign-archive.com/home/?u=83db137b4bc99ea8531555f74&id=9493250305"

export const dd_mm = (d, format = "ET") => {
    const date = new Date(d);
    const estonianDate = new Date(date.toLocaleString("en-US", { timeZone: "Europe/Tallinn" }));
    const day = String(estonianDate.getDate()).padStart(2, '0');

    if (format === "EN") {
        const monthAbbr = estonianDate.toLocaleString("en-US", { month: "short" });
        return `${monthAbbr} ${day}`;
    } else {
        const month = String(estonianDate.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
    }
};

const MaillistForm = ({ status, message, onValidated,texts,en }) => {
    let email;
    const submit = () =>
        email.value.indexOf("@") > -1 && onValidated({ EMAIL: email.value });
    return (<>
        {status === "sending" && <div style={{ color: "#4046ca", marginBottom:20 }}>{en ? message : "Lisan..."}</div>}
        {status === "error" && (<div style={{ color: "#f68511", marginBottom:20 }}>{en ? message : "Midagi läks valesti, palun proovi uuesti."}</div>)}
        {status === "success" && (<div style={{ color: "#4046ca", marginBottom:20 }}>{
            en ? message :
            message === "Thank you for subscribing!" ? "Lisatud. Aitäh!" :
                message === "You're already subscribed, your profile has been updated. Thank you!" ? "Oled juba liitunud. Aitäh!" : message}</div>)}
        <div>
            <input ref={node => (email = node)} type="email" placeholder={texts.placeholder} />
            <button onClick={submit}>Liitu</button>
        </div>
    </>);
};

export const News = () => {
    const {search} = useLocation();
    const language = useSelector(path(["ui","language"]));
    const openRef = useRef(null);
    const { data } = useNewsQuery(language === "EN" ? {lang:"EN"} : {});
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [formVisible, setFormVisible] = useState(false);
    const opened_news = useSelector(path(["ui","open_news"]));
    const [opened_years,toggle_year] = useState({});
    const {data:translation} = useLangQuery(language);
    
    const getYear = dateString => new Date(dateString).getFullYear();
    const getMonth = dateString => new Date(dateString).toLocaleString(language === "EN" ? 'en-GB' : 'et-EE', { month: 'long' });
    const isEmpty = (obj) => Object.keys(obj).length === 0;

    const find_year = C(split("-"),defaultTo(""),p("post_date"),p(__,indexBy(p("slug"),data || [])))

    useEffect(() => {
        const params = new URLSearchParams(search);
        if(params.has("liitu")) setFormVisible(1);
        if (data && isEmpty(opened_years)) {
            const years = C(
                fromPairs,
                addIndex(m)((x, n) => [x, n === 0]),
                uniq,
                m(C(getYear, p("post_date"))),
            )(data);
            const [year] = find_year(slug)
            toggle_year(() => ({...years, [year]: true }));
        }
    },[data,find_year,opened_years,slug,search]);
    useEffect(() => {
        dispatch(open_news(slug));
        setTimeout(scroll_into_view(openRef,180), 1000);
    },[openRef,opened_news,slug,data,dispatch]);
     

    const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
    };

    const sortedGroupedData = data
        ? C(
            sort(descend(head)),
            toPairs,
            m(C(
                sort(descend(C(p("post_date"),last))),
                toPairs,
                m(sort(descend(p('post_date')))),
                g(x => getMonth(x.post_date))
            )),
            g(x => getYear(x.post_date))
        )(data)
        : [];

    return data ? (
        <>
            <div className="news-header">
                <div className="news-heading">
                    <h1>{translation["news"]}</h1>
                    <button className="inline-news-link" onClick={toggleFormVisibility}>
                        {formVisible ? translation["hide_form"] : translation["newsletter"]}
                    </button>
                    <a className="inline-news-link" target="_blank" rel="noreferrer" href={archive}>{translation["all_newsletters"]}</a>
                </div>
                <div className={`join-maillist ${formVisible ? 'visible' : 'hidden'}`}>
                    <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                            <MaillistForm
                                status={status}
                                message={message}
                                en={language === "EN"}
                                onValidated={formData => subscribe(formData)}
                                texts={{
                                    placeholder:translation["your_email"]
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="newslist">{sortedGroupedData.map(([year, months]) => (
                <div key={year} className={`year-block ${opened_years[year] ? "opened-year" : ""}`}>
                    <h2 onClick={() => {toggle_year({...opened_years, [year]:!opened_years[year]})}}>{year}</h2>
                    {months.map(([month, monthItems]) => (
                        <div key={month} className="month-block">
                            <h3>{month}</h3>
                            <ul className="news-grid">{monthItems.map(x => {
                                const closed = opened_news !== x.slug;
                                return <li  
                                    ref={!closed ? openRef : null}
                                    className={closed ? "" : "opened"} key={x.slug}>
                                    {closed ? <>
                                        <h4><span><b>{dd_mm(x.post_date,language)}</b></span>{x.title}</h4>
                                        <Link  className="newslink" to={`/uudised/${x.slug}`}/>
                                     </> :
                                    <EditableCollapsible 
                                        slug={{slug:x.slug}}
                                        key={x.slug}
                                        collapsed={closed}
                                        className="singlenews"
                                        validations={{title: Yup.string(),content: Yup.string()}}
                                        queryFn={useNewsQuery}
                                        mutationFn={useUpdateNewsMutation}
                                        FormElements={({data}) => <>
                                            <EditableText name="title" className="h4_input"/>
                                            <input type="date" name="post_date" lang="et-EE" value={data[0].post_date}/>
                                            <EditableText name="content"/>
                                        </>}
                                        RenderElements={({data}) => <>
                                            <Link to={`/uudised`} className="news_back">
                                                <img src="/back.svg" alt="back button" className="back"/>
                                            </Link>
                                            <h4><span><b>{dd_mm(data?.[0]?.post_date,language)}</b></span>{data?.[0]?.title}</h4>
                                            <ReactMarkdown className={"formattedText"} remarkPlugins={[sectionize]} 
                                                children={data?.[0]?.content}/>
                                        </>}/>}
                                </li>})}
                            </ul>
                        </div>              
                    ))}
                </div>))}
            </div>
        </>
    ) : null;
};
