import { map as m, path, values } from "ramda";
import { useDispatch, useSelector } from "react-redux"
import { useDepartmentsQuery } from "../../app/api";
import "./category_select.css"
import { useNavigate } from "react-router-dom";
import { setTopics } from "../../app/set_actions";

export const CategorySelect = () => {
    const {data} = useDepartmentsQuery(); // vajalik, et initiks ui departments
    const departments = useSelector(path(["ui","departments"]));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const update_selected = (key) => () => {
        dispatch(setTopics([key,navigate]));
    }
    return <div className="btns">
    {m(({icon,id,show,name}) => <div className="icon_btn" onClick={update_selected(id)} key={"btn_" + id} >
        <img
            loading="lazy"
            decoding="async"
            title={name}
            key={"osakond_"+id}
            src={"/uploads/ui/"+icon}
            style={{opacity: show ? 1 : 0.25}} 
            name={"o_"+id}/>
        <span>{name}</span>
        </div>,values(departments))} 
</div>
}