import React from "react";
import { useDepartmentsQuery, useLangQuery, usePeopleQuery } from "../app/api";
import { Part } from "./part";
import './part.css';
import './jobs.css';
import { compose as C, filter as f, includes, indexBy, map as m, prop as p, path, propEq, reject, tap, toUpper } from "ramda";
import { add_size } from "../features/helpers";
import { fullname } from "../features/people/people";
import { useSelector } from "react-redux";

export const Jobs = () => {
  const language = useSelector(path(["ui","language"]));
  const {data:translation} = useLangQuery(language);
  const {data:people,isLoading:load_p} = usePeopleQuery({}); 
  const {data:departments,isLoading:load_o} = useDepartmentsQuery(); 

  const managers = f(C(
    includes(3),
    m(p("osakond_id")),
    p("Inimene_Osakond")
  ));
  
  return (!load_o && !load_p) ? <>
    <h1 id="jobs">{toUpper(translation["join"])}</h1>
    <div className="managers">
      <Part style={{minWidth: "100%", textAlign:"left"}} part_id={language === "EN" ? 83 : 82}/>
      {m(x => <div className="manager">
        <h3>{indexBy(p("id"),departments)[x.osakond_id].name}</h3>
        <img src={add_size(300,x?.pilt)} key={x?.slug+"-pic"} title={fullname(x)} 
          alt={fullname(x) + " profiilipilt"} decoding="async" className="profile_img" loading="lazy"/>
        <h4>{fullname(x)}</h4>
        <a href={"mailto:"+x.email}>{x.email}</a>
      </div>,people ? reject(propEq("osakond_id",3),managers(people)) : [])}
    </div>
  </> : null;
}