import { compose as C, flatten, identity as id, join, juxt, map as m, 
    prop as p, props, slice, reverse, mergeRight, apply, __
    } from "ramda";
import { add_size, slug } from "../helpers";

const placeholder = "/uploads/ui/placeholder.svg"   

export const fullnames = C(
    apply(mergeRight),
    juxt([
        C(x => ({type:"name",fullname:x}),
          join(" "),
          props(["firstname","lastname"]),),
        id]),
    p("people")
)
export const group_over_3 = C(flatten,juxt([
    C(x => 
        x.length > 1 ? [{type:"sum",number:x.length}] : 
        x.length === 1 ? m(fullnames,x) : 
        [],
        slice(3,Infinity)),
    C(m(fullnames),slice(0,3))
]));

export const avatar_or_number = ({fullname,number,type,pilt}) => 
    type === "sum" ? 
        <span key={slug(fullname)} className="number-circle">+{number}</span> : 
    pilt ? 
        <img
            key={slug(fullname)}
            src={add_size(120,pilt)}
            title={fullname}
            alt={fullname} 
            decoding="async"
            className="avatar"
            loading="lazy"
            sizes="(max-width: 40px) 100vw, 40px" 
            srcSet={`
                ${add_size(40,pilt)} 40w, 
                ${add_size(120,pilt)} 120w, 
                ${add_size(300,pilt)} 300w`} /> :
        <img 
            key={slug(fullname)}
            src={placeholder} 
            title={fullname} 
            alt={fullname} 
            decoding="async" 
            className="avatar no_border" 
            loading="lazy"/>


export const Authors = ({data}) => {
    return <div className="people">
        {C(
            m(avatar_or_number),
            reverse,group_over_3,
            p("Projekt_Inimene"))(data)}
    </div>
}