import * as React from "react";

const Facebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Zm0-28c5.52 0 10 4.48 10 10 0 5.19-3.95 9.45-9 9.95V21h3v-3h-3v-2c0-.55.45-1 1-1h2v-3h-2.5c-1.93 0-3.5 1.57-3.5 3.5V18h-2v3h2v6.8c-4.56-.93-8-4.96-8-9.8 0-5.52 4.48-10 10-10Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Facebook;