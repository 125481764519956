import { useState, useEffect } from 'react'
import { supabase } from './api'
import Avatar from './Avatar'

export default function Account({ session }) {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [website, setWebsite] = useState(null)
  const [avatar_url, setAvatarUrl] = useState(null)

  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      const { user } = session
      let { data, error } = await supabase
        .from('profiles')
        .select(`username, website, avatar_url`)
        .eq('id', user.id)
        .single()
      
      if (error) {
        console.warn(error)
      } else if (data) {
        setUsername(data.username)
        setWebsite(data.website)
        setAvatarUrl(data.avatar_url)
      }
      setLoading(false)
    }
    getProfile()
  }, [session])

  async function updateProfile(event) {
    event.preventDefault()
    setLoading(true)
    const { user } = session
    const updates = {id: user.id, username, website, avatar_url, updated_at: new Date()}
    let { error } = await supabase.from('profiles').upsert(updates)
    if (error) alert(error.message);
    setLoading(false)
  }

  return (
    <form className="loginform account" onSubmit={updateProfile}>
      <div>
        <label htmlFor="email">E-MAIL</label>
        <input id="email" type="text" value={session.user.email} disabled />
      </div>
      <div>
        <label htmlFor="username">NIMI</label>
        <input id="username" type="text" required value={username || ''} 
          onChange={(e) => setUsername(e.target.value)}/>
      </div>
      <div>
        <button type="submit" disabled={loading}>
          {loading ? ' laadib ...' : 'Uuenda'}
        </button>
        <button type="button" className="orange" onClick={() => supabase.auth.signOut()}>
          Logi välja
        </button>
      </div>
    </form>
  )
}