import { filter as f, map as m,path, last, head} from "ramda";
import { useNavigate } from "react-router-dom"
import { useLangQuery, useProjectsQuery } from "../../app/api";
import "./project.css";
import './../../pages/part.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from "react";
import { ProjectTabs } from "./project";
import { scroll_into_view } from "../helpers";
import { setPeriod, setSelected } from "../../app/set_actions";

export const Projectlist = () => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const selected = useSelector(path(["ui","selected"]));
    const selected_filter = useSelector(path(["ui","selected_filter"]));
    const nodes = useSelector(path(["ui","nodes"]));
    const {low,high} = useSelector(path(["ui","yearrange"]));
    const timeline_range = useSelector(path(["ui","timeline_range"]));
    const projects = useProjectsQuery();
    const openedProjectRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (openedProjectRef.current && nodes) {
            const refEl = openedProjectRef.current;
            const marginTop = parseInt(refEl.style.marginTop)+180 || 180;
            setTimeout(scroll_into_view(openedProjectRef,marginTop),1)}
        else {
            window.scrollTo(0, 0);
        }
        }, [openedProjectRef,selected,nodes]);

    return <ul className="projects">{projects?.data ? 
    <>{m(x => {
        const closed = selected?.slug !== x.slug && selected?.slug?.split("/")[0] !== x.slug;
        return <li 
            style={{marginTop:closed ? 0 : (x?.children?.length || 0) * 60}}
            className={closed ? "collapsed" : ""}
            ref={!closed ? openedProjectRef : null}
            key={x.slug} >
            <ProjectTabs main={x} selected={selected} closed={closed}/>
        </li>},
        f(x => x.group === "projekt", nodes))}
        <div className="move_forward">
            {low === high && head(timeline_range) < low ? <button className="more_projects" 
                onClick={() =>{
                    dispatch(setPeriod([{low:low-1,high:high-1},navigate]));
                    dispatch(setSelected(["remove",navigate]));
                }}>{translation["older"]}</button> : 
                null
            }
            {low === high && last(timeline_range) > high ? <button className="more_projects" 
                onClick={() =>{dispatch(setPeriod([{low:low+1,high:high+1},navigate]))}}>{translation["newer"]}</button> : 
                null
            }
        {selected_filter ? null : <p>{translation["choose_year"]}</p>}
        </div>
    </> : ""}
    </ul>
}