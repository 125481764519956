import { useDispatch, useSelector } from "react-redux";
import { Link } from "../features/navigation/Navigation"
import { useLangQuery } from "../app/api";
import { path } from "ramda";
import "./notfound.css"
import { toggle_search } from "../app/ui_state";

export const NotFound = () => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const dispatch = useDispatch();
    return <div className="not_found">
        <h1>{translation["nothing_here"]}</h1>
        <button className="open_search" onClick={() => dispatch(toggle_search(true))}>{translation["help"]}</button>
        <Link text={translation["go_home"]} to="/" />
    </ div>}