import * as React from "react"

const Logo = ({color,height,onClick}) => (
  <svg id="logo" viewBox="0 0 70 70" fill="none" onClick={onClick} xmlns="http://www.w3.org/2000/svg">
    <circle cx={15} cy={15} r={15} fill={color} />
    <circle cx={15} cy={55} r={15} fill={color} />
    <circle cx={55} cy={15} r={15} fill={color} />
    <circle cx={55} cy={55} r={15} fill={color} />
  </svg>)

export default Logo
