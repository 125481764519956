import React, { useEffect, useState } from 'react';
import '../App.css';
import { groupBy as g, prop as p, compose as C, indexBy, sort, map as m, descend,path, filter as f } from 'ramda';
import { add_size } from './helpers';
import { Link } from 'react-router-dom';
import { AppLink } from './navigation/applink';
import { useLangQuery, useLazySearchQuery, usePeopleQuery } from '../app/api';
import { useDispatch, useSelector } from 'react-redux';
import { menu_open, toggle_search ,show_info } from '../app/ui_state';

export const Search = () => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const opened = useSelector(path(["ui","search_open"]));
    const [searchResults, setSearchResults] = useState([]);
    const {data:people} = usePeopleQuery();
    const dispatch = useDispatch();
  
    const [triggerSearch, { data: queryResult }] = useLazySearchQuery(); // Use lazy query
  
    useEffect(() => {
      if (queryResult) {
        const data = C(g(p("type")))(queryResult)
        const active_ids = indexBy(p("id"),people);
        const mapped_data = {...data, 
          news: f(x => language === x.lang, data.news || []),
          inimene: C(
            sort(descend(p("active"))),
            m(x => ({...x,...(p(x.id,active_ids) || {active:false})}))
          )(data.inimene || [])}
      
        setSearchResults(mapped_data);
      }
    }, [queryResult,people,language]);
  
    const query = async (e) => {
      e.preventDefault();
      const data = e.target[0].value;
      if (data.length > 1) {
        triggerSearch({obj:data,is_en:language === "EN"}); // Trigger the search
      } 
    };
    return <div className={opened ? "opened" : "closed"}>
        <div className="bg-close" onClick={() => { dispatch(toggle_search(false));}}></div>
        <div id="search" onClick={x => { dispatch(toggle_search());dispatch(menu_open(false));dispatch(show_info(false)); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.4126 13.9347C8.61434 10.5351 9.14604 6.22575 12.0077 3.36407C15.5224 -0.150652 21.2209 -0.150652 24.7356 3.36407C28.2504 6.87879 28.2504 12.5773 24.7356 16.092C21.7929 19.0348 17.3192 19.5136 13.8782 17.5286L4.94415 26.4627C4.1631 27.2438 2.89677 27.2438 2.11572 26.4627L1.4144 25.7614C0.633351 24.9803 0.633351 23.714 1.4144 22.933L10.4126 13.9347ZM14.8362 6.19249C12.8835 8.14512 12.8835 11.3109 14.8362 13.2636C16.7888 15.2162 19.9546 15.2162 21.9072 13.2636C23.8598 11.3109 23.8598 8.14511 21.9072 6.19249C19.9546 4.23987 16.7888 4.23987 14.8362 6.19249Z" />
            </svg>
        </div>
        <div id="searchbar">
        <label htmlFor="search_phrase">{translation["search"]}</label>
        <form onSubmit={query}>
            <input id="search_phrase" type="text" onClick={(e) => { e.stopPropagation() }} />
            <button onClick={(e) => { e.stopPropagation() }} action="submit">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
                    <path fill="black" fillRule="evenodd" clipRule="evenodd" d="M10.4126 13.9347C8.61434 10.5351 9.14604 6.22575 12.0077 3.36407C15.5224 -0.150652 21.2209 -0.150652 24.7356 3.36407C28.2504 6.87879 28.2504 12.5773 24.7356 16.092C21.7929 19.0348 17.3192 19.5136 13.8782 17.5286L4.94415 26.4627C4.1631 27.2438 2.89677 27.2438 2.11572 26.4627L1.4144 25.7614C0.633351 24.9803 0.633351 23.714 1.4144 22.933L10.4126 13.9347ZM14.8362 6.19249C12.8835 8.14512 12.8835 11.3109 14.8362 13.2636C16.7888 15.2162 19.9546 15.2162 21.9072 13.2636C23.8598 11.3109 23.8598 8.14511 21.9072 6.19249C19.9546 4.23987 16.7888 4.23987 14.8362 6.19249Z" />
                </svg>
            </button>
        </form>
        <div className="search-results" onClick={(e) => { e.stopPropagation(); dispatch(toggle_search(false)); }}>
        {searchResults?.field && searchResults.field.length > 0 && (
            <div>
            <h3>{translation["topics"]}</h3>        
            <ul>
                {searchResults?.field?.map((result, index) => (
                <li className="category-result" key={index}>
                  <AppLink to={`/teemad/${result.slug}`} selected={result}>{language === "EN" ? result.name_eng : result.name}</AppLink></li>
                ))}
            </ul>
            </div>
      )}
      {searchResults?.inimene && searchResults.inimene.length > 0 && (
        <div>
          <h3>{translation["people"]}</h3>
          <ul>
            {searchResults?.inimene?.map((result, index) => (
              <li className="person-result" key={index}>
                {result.active ? 
                  <AppLink to={`/inimesed/${result.slug}`} selected={result}>
                    <img src={add_size(120,result.pilt)} alt={result.name + "pilt"}/>
                    <p>{result.name}</p>
                  </AppLink>
                : <p>{result.name}</p>}
              </li> 
            ))}
          </ul>
        </div>
      )}
      {searchResults?.project && searchResults.project.length > 0 && (              
      <div>
        <h3>{translation["works"]}</h3>
        <ul>
          {searchResults?.project?.sort((a,b) => a.active - b.active).map((result, index) => (
            <li className="project-result" key={index}>
              <AppLink to={`/tood/${result.slug}`} selected={{...result,set_period:true}}>{language === "EN" ? result.name_eng : result.name}</AppLink>
            </li> 
          ))}
        </ul>
      </div>
      )}
      {searchResults?.news && searchResults.news.length > 0 && (              
      <div id="news-results">
        <h3>{translation["news"]}</h3>
        <ul>
          {searchResults?.news?.map((result, index) => (
            <li className="news-result" key={index}>
              <Link to={`${result.lang === "EN" ? "/en/" : "/"}uudised/${result.slug}`}>{
                (language !== result.lang ? (result.lang === "EN" ? "ENG | " : "EST | ") : "") +  result.name
                }</Link>
            </li> 
          ))}
        </ul>
      </div>
      )}
    </div>
  </div>
  </div>


}