import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { supabaseApi } from './api';
import ui_state from './ui_state';
/* import projects_slice from './projects_slice';
import people_slice from './people_slice';
import categories_slice from './categories_slice';
import links_slice from './links_slice'; */

export const store = configureStore({
  reducer: {
    ui: ui_state,
/*     projects: projects_slice,
    people: people_slice,
    categories: categories_slice,
    links: links_slice, */
    [supabaseApi.reducerPath]: supabaseApi.reducer,
  },
  middleware: middleware =>  middleware({serializableCheck: false}).concat(supabaseApi.middleware),
})


setupListeners(store.dispatch)