import { apply, chain, compose as C, head, includes, last, max, min, path, range, uniq } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import "./timeline.css"
import { useEffect, useState } from "react";
import { setPeriod, setSelected} from "../../app/set_actions";
import { useNavigate } from "react-router-dom";
import { TimelineDots } from "./dots";
import { project_in_range } from "../helpers";

const SliderInput = ({ timeline_range, val, id,other,setFn,setOther,height, multi}) => {
  const dispatch = useDispatch();
  const selected = useSelector(path(["ui","selected"]));
  const current_authors = useSelector(path(["ui","only_current"]));
  const navigate = useNavigate();

  const update_selection = (x) => {
    const new_v = Number(x.target.value);
    const aastaid_korraga = 0;
    const new_o = aastaid_korraga === 0 ? new_v : 
      (Math.abs(new_v - other) <= aastaid_korraga) ? other :
      (new_v > other) ? new_v-1 : new_v+1;
    setOther(new_o)
    setFn(new_v);
    dispatch(setPeriod([{low:min(new_v,new_o),high:max(new_v,new_o)},navigate]));
    const uniq_years = C(uniq,chain(apply(range)))

    if(selected && (
      selected.group === "projekt" ? !project_in_range({low:min(new_v,new_o),high:max(new_v,new_o)},selected) : 
      (selected.group === "inimesed" && current_authors) ? !selected.active : 
      !(includes(new_v,uniq_years((selected.year_ranges || [])))))
    ) {
        dispatch(setSelected(["remove",navigate]));
      }
  };
  
  return (
    <input
      onInput={update_selection}
      className={multi ? "vertical-range smaller" : "vertical-range"}
      type="range"
      min={head(timeline_range)}
      max={last(timeline_range)}
      value={val}
      step="1"
      id={id}
      style={{height}}
    />
  );
};

export const Timeline = () => {
  const { low, high } = useSelector(path(["ui", "yearrange"]));
  const [first,setFirst] = useState();
  const [second,setSecond] = useState();
  const screen_w = useSelector(path(["ui","width"]));
  const screen_h = useSelector(path(["ui","height"]));

  const timeline_range = useSelector(path(["ui", "timeline_range"]));
  const selected = useSelector(path(["ui","selected"]))
  const el_height = (screen_w >= 720 && screen_h >= 720) ? 395 : 400;
  const unit_h = (el_height / (timeline_range ? timeline_range.length : 26));
  const this_year = (new Date()).getFullYear();

  useEffect(() => {
    setFirst(low);
    setSecond(high);
  },[low,high])

  
  const duration_ranges = !selected ? [] :
    selected.group === "projekt" ? [{...selected}] :
    selected.group === "inimene" ? selected.Tootamised : 
    selected.group === "field" ? (selected.year_ranges || []).map(([start,end]) => ({start,end})) : 
    [];
  
  const isDataReady = timeline_range && unit_h && screen_w && screen_h && first && second;

  const top = (n) => 8+ unit_h * n;
  const width = (n) => (n % 5 ? 8 : 20);
  const left = (n) => (n % 5 ? 11 : 5);

  const is_multi_range = first && second && timeline_range.length && first !== second;

  return isDataReady ? (
    <div className="timeline">
      <div className="vertical-multi-range"> {}
        {is_multi_range ? <span className="connecting_line" 
          style={{ 
            border: "3px solid black",
            top: timeline_range.indexOf(first) && top(timeline_range.indexOf(first))-6, 
            left: 7,
            width: 16,
            borderRadius:8,
            height: (second-first) * unit_h + 16}}/> : ""}
        {timeline_range && duration_ranges.map((x,n,xs) => (
          <span key={"duration_"+n} className="duration_line" 
            style={{ 
              top: top(timeline_range.indexOf(x.start)) + ((x.end-x.start) === 0 ? -4 : 2), 
              background: x.color,
              borderRadius: ((x.end-x.start) === 0) ? 8 :0,
              left: ((x.end-x.start) === 0) ? 9 : 11,
              width: ((x.end-x.start) === 0) ? 12 : 8,
              height: ((x.end-x.start) === 0) ? 12 : (((x.end || this_year)-x.start)) * unit_h}}/>))}
        {timeline_range
          .map((x, n, xs) => [x, top(n)])
          .filter((x, n) => !(n % 5))
          .map(([num, pos]) => (
            <span key={"timeline_number_"+num} className="number" style={{ top: pos }}>{}
              {num}
            </span>
          ))}
        {timeline_range.map((x, n, xs) => (
          <span
            key={"timeline_tick_"+n}
            className="vertical-tick" 
            style={{ top: top(n), width: width(n), left: left(n) }}
          ></span>
        ))}
        <SliderInput height={el_height} timeline_range={timeline_range} val={first} setFn={setFirst} 
          multi={is_multi_range} setOther={setSecond} other={second} id={"first"} />
        <SliderInput height={el_height} timeline_range={timeline_range} val={second} setFn={setSecond}
          multi={is_multi_range} setOther={setFirst} other={first} id={"second"} />
        <TimelineDots/>
      </div>
    </div>
  ) : null;
};
