import { createClient } from "@supabase/supabase-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } from "./constants";
import { compose as C, apply, invoker, map as m, unapply, omit, pick, type, toPairs, indexBy, prop as p,
   evolve, chain, props, fromPairs, groupBy as g} from "ramda";

export const supabase = createClient(REACT_APP_SUPABASE_URL,REACT_APP_SUPABASE_KEY);

const invokeAll = (xs,data) => C(...m(
  apply(unapply(([method, ...args]) => invoker(args.length, method)(...args))),
  xs))(data);



export const supabaseApi = createApi({
    name: "api",
    baseQuery: fakeBaseQuery(),
    tagTypes: ["people","praxis","project","projects","avatars","categories","news","departments",
      "nodes","links","search","lang"],
    endpoints: build => ({
      search: build.query({
        providesTags: ["search"],
        queryFn: async ({obj,is_en}) => {
          const response = await supabase.from("searchview").select().ilike(is_en ? "text_eng" : "text","%"+obj+"%"  );
          return pick(["data","error"],response);
        }
      }),
      people: build.query({
        providesTags: (result, error, page) => result && result.data ? 
          [...result.data.map(({ id }) => ({ type: 'people', id })),{ type: 'people', id: 'list' }]
          : [{ type: 'people', id: 'list' }],
        queryFn: async (obj) => {
          const response = await invokeAll([
            ...(type(obj) === "Object" ? m(x => ["eq",...x],toPairs(obj)) : []),
            (type(obj) === "String" ? ["eq","slug",obj] : ["or",'active.eq.true']),
            ['select',`*, 
              Inimene_Osakond(
                osakond_id, 
                Osakonnad(order_nr,name,name_eng)), 
              too_aastad(year), 
              Tootamised(start,end)`],
            ['from',"people"]],supabase);
          return pick(["data","error"],response);
        }}),
      projects: build.query({
        providesTags: (result, error, page) => result && result.data ? 
          [...result.data.map(({ id }) => ({ type: 'project', id })),{ type: 'projects', id: 'list' }]
          : [{ type: 'projects', id: 'list' }],
        queryFn: async (slug) => {
          if (slug !== "empty-array") {
            const response = await invokeAll(!slug ? [
              ["order",'end',{ ascending: false ,nullsFirst: false}],
              ["order",'start',{ ascending: false,nullsFirst: false }],
              ["eq","visible",true],
              ['select',`id,ui_id,parent_id, name,projectname_eng, start, end, visible,slug_eng, slug, osakond_id, color, value, children,group,years,
                Projekt_Inimene(
                  people(
                    *,
                    too_aastad(year),
                    Tootamised(start,end))),
                Projekt_Kategooria(*,
                  categories(id,parent_id,osakond_id,children,slug,path,icon,name,name_eng,color,x_shift,y_shift))`],
              ['from',"projects"]
            ] : [
              ["in",Array.isArray(slug) ? "id" : "slug",Array.isArray(slug) ? slug : [slug]],
              ['select',`*, 
                  Projekt_Inimene(people (*)), 
                  Projekt_Kategooria(kategooria_id,categories(slug,id,icon,color,name,name_eng)), 
                  Projekt_Partner(comment,Partnerid(*)),
                  Projekt_Projektifailid(*)`],
              ['from',"projects"]
            ],supabase);
            return pick(["data","error"],response);
          } else {
            return {data:[]};
          }
        },
      }),
      departments: build.query({
        queryFn: async () => {
          const response = await invokeAll([['select',"*"], ['from',"Osakonnad"]],supabase);
          return pick(["data","error"],response);
        },
        providesTags: ["departments"]
      }),
      links: build.query({
        queryFn: async () => {
          const response = await supabase.from("links").select("*");
          return evolve({data:chain(p("rows"))},pick(["data","error"],response));
        },
        providesTags: ["links"]
      }),
      categories: build.query({
        queryFn: async () => {
          const response = await invokeAll([['select',"*"], ['from',"categories"]],supabase);
          return pick(["data","error"],response);
        },
        providesTags: ["categories"]
      }),
      news: build.query({
        providesTags: (result, error, page) => result && result.data ? 
          [...result.data.map(({ id }) => ({ type: 'news', id })),{ type: 'news', id: 'list' }]
          : [{ type: 'news', id: 'list' }],
        queryFn: async ({slug,lang}={}) => {  
          const response = await invokeAll([
            ["order" ,'post_date', { ascending: false }],
            (
              slug ? ["eq","slug",slug] : 
              lang ? ['eq','lang', lang] : 
                     ["is","lang",null]
            ),
            ["or",'draft.eq.false'],
            ['select',slug ? "*" : "title,created_at,post_date,slug"],
            ['from',"Uudised"]],supabase);
          const dates_converted = response.data ? 
            {data: C(m(x => {
              const d = new Date(x.post_date);
              const formatted = d.toISOString().split('T')[0];
              return {...x, post_date: formatted}
            }))(response.data)} : 
            pick(["error"],response);
          return dates_converted;
        }}),
      parts: build.query({
        queryFn: async () => {
          const response = await supabase.from('Leheosad').select("*");
          return response && response.data ? {data:indexBy(p("id"),response?.data)} : pick(["error"],response)},
        providesTags: ["praxis"]
      }),
      lang: build.query({
        queryFn: async (lang) => {
          const response = await supabase.from('UI_tekstid').select("*");
          return response?.data ? {
            data:C(
              p(lang),
              m(C(fromPairs,m(props(["field","value"])))),
              g(p("lang"))
            )(response?.data)
          } : pick(["error"],response)},
        providesTags: ["lang"]
      }),
      avatars: build.query({
        queryFn: async (ids) => {
          const response = await supabase.from("inimesed").select("slug,pilt");
          return pick(["data","error"],response)},
        providesTags: ["avatars"]
      }),
      upsertParts: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Leheosad').upsert(obj).select();
          return result},
        invalidatesTags: ["praxis"],
      }),
      upsertCategories: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Kategooriad').upsert(obj).select();
          return result},
        invalidatesTags: ["categories"],
      }),
      updateProject: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Projektid').update(omit(["id"],obj)).eq("id",obj.id).select();
          return result},
        invalidatesTags: ["project","projects"],
      }),
      updateNews: build.mutation({
        queryFn: async (obj) => {
          const response = await supabase.from("Uudised").update(omit(["id"],obj)).eq("id",obj.id).select();;
          return pick(["data","error"],response)},
        providesTags: ["news"]
      }),
      updatePerson: build.mutation({
        queryFn: async (obj) => {
          const result = await supabase.from('Inimesed').update(omit(["id"],obj)).eq("id",obj.id).select();
          return result},
        invalidatesTags: ["person"],
      })
    })
})
export const {
  useLazySearchQuery,
  useDepartmentsQuery,
  useLangQuery,
  useCategoriesQuery,useUpsertCategoriesMutation,
  useProjectsQuery,
  useProjectQuery,useUpdateProjectMutation,
  usePeopleQuery,
  useLinksQuery,
  useNewsQuery,useUpdateNewsMutation,
  usePersonQuery,useUpdatePersonMutation,
  usePartsQuery,useUpsertPartsMutation,
  useAvatarsQuery
} = supabaseApi;