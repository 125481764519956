import * as React from "react";
const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Zm10-22.2c0-3.2-2.6-5.8-5.8-5.8h-8.4A5.8 5.8 0 0 0 8 13.8v8.4c0 3.2 2.6 5.8 5.8 5.8h8.4a5.8 5.8 0 0 0 5.8-5.8v-8.4Zm-16.946-2.746A3.6 3.6 0 0 1 13.6 10h8.8c1.99 0 3.6 1.61 3.6 3.6v8.8a3.6 3.6 0 0 1-3.6 3.6h-8.8c-1.99 0-3.6-1.61-3.6-3.6v-8.8a3.6 3.6 0 0 1 1.054-2.546Zm13.08.812a1.25 1.25 0 1 0-1.768 1.768 1.25 1.25 0 0 0 1.768-1.768Zm-2.599 2.598a5 5 0 1 0-7.07 7.07 5 5 0 0 0 7.07-7.07ZM15.88 15.88a3 3 0 1 1 4.242 4.242 3 3 0 0 1-4.242-4.242Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Instagram;