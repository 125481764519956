import {reject, compose as C, head, last, map as m, prop as p, values} from 'ramda';
import { set_selected, set_selected_filter, reset_departments, set_departments, 
    toggle_current,set_url } from './ui_state';
import { createParams, project_in_range } from '../features/helpers';
import { initialState } from './initial';
    
const path_root = (x,lang) => {
  return x.split("/").slice(0, lang ? 3 : 2).join("/")
}
const yearrange_selection = ({ timeline_range, yearrange, selected }, type) => {
    const { yearrange: { low: i_low, high: i_high } } = initialState;
    const min_low = head(timeline_range);
    const max_high = last(timeline_range);
    const is_full_range = min_low === yearrange.low && max_high === yearrange.high;
    const is_initial = i_low === yearrange.low && i_high === yearrange.high;
    const reset_range = (type === undefined || is_full_range || is_initial);
    const initial_range = { low: i_low, high: i_high };
    const in_range = selected ? project_in_range(reset_range ? initial_range : yearrange, selected) : false;
    return {
    is_full_range, is_initial, reset_range, in_range, initial_range,
    undefined_range: { low: undefined, high: undefined },
    full_range: { low: min_low, high: max_high }
    };
};
const get_selection = (ui,val) => {
    const selection = ui.all_data && val?.slug ? ui.all_data.find(x => x.slug === val.slug) : val;
    return val?.slug ? {...selection,...val} : undefined;
}
const setUrl = ([params, navigate, path, history]) => (dispatch, getState) => {
    const { ui: { url,lang } } = getState();
    const updatedUrl = {
        params: { ...url.params, ...params},
        path: (path === "remove" && /tood|inimesed|teemad/.test(url.path)) ? url.path.split("/").slice(0,lang === "EN" ? 3 : 2).join("/") : (path || url.path)
    };
    dispatch(set_url(updatedUrl));
    navigate(
        `${lang === "EN" ? "en/" : ""}${updatedUrl.path}?${createParams(updatedUrl.params)}`,
        { replace: history, state: updatedUrl.params });
};
export const setSelected = ([val, navigate, path, history]) => (dispatch, getState) => {
  const {ui } = getState();
  const period_change = (val?.set_period) ? { low: val.end || val.start, high: val.end || val.start, filter:undefined } : {};
  dispatch(set_selected(get_selection(ui,val)));
  dispatch(setUrl([{ selected: val ? (val.selected || val.ui_id || val.id) : undefined, ...period_change,liitu:undefined}, navigate, path, history]));
};
export const setSelectedFilter = ([val, navigate, path, history, type]) => (dispatch, getState) => {
  const {ui} = getState();
  const { is_full_range, reset_range, full_range, in_range, initial_range } = yearrange_selection(ui, type);
  const right_path = (reset_range && type !== "all") ? path_root(ui.url.path, ui.language !== "ET") : path;
  const only_active = (ui.only_current && ui.selected?.group === "inimene") ? ui.selected.active : true;
  const selected = val ? (val.ui_id || val.id) : undefined;
  const url_params = {
    ...((in_range || only_active) ? {} : {selected}),
    ...(type === "all" ? full_range : (val === undefined && is_full_range) ? initial_range : {}),
    filter: type === "all" ? val.ui_id || val.id : undefined
  };

  if (!in_range || !only_active) dispatch(set_selected(get_selection(ui,val))); 
  dispatch(setUrl([url_params, navigate, right_path, history]));
};
export const setTopics = ([val, navigate, path, history]) => (dispatch, getState) => {
  dispatch(set_departments(val));
  const { ui: { departments } } = getState();
  const department_ids = C(values, m(p("id")), reject(p("show")))(departments);
  dispatch(setUrl([{ topics: department_ids }, navigate, path, history ]));
};
export const resetTopics = ([val, navigate, path, history]) => (dispatch, getState) => {
  const { ui: { departments } } = getState();
  dispatch(reset_departments(m(x => ({...x,show:true}),departments)));
  dispatch(setUrl([{topics: []}, navigate, path, history ]));
};
export const setAuthors = ([val, navigate, path, history]) => (dispatch) => {
  dispatch(toggle_current(val));
  dispatch(setUrl([val, navigate, path, history ]));
};
export const setPeriod = ([val, navigate, path, history]) => (dispatch, getState) => {
    const {ui} = getState();
    const {in_range,is_full_range,initial_range} = yearrange_selection(ui, "range");

    const right_path = !in_range ? path_root(ui.url.path, ui.language !== "ET") : path;
    const params = {...(val || initial_range),
      ...(!in_range ? {selected:undefined}: {}),
      ...((!val || is_full_range) ? {filter:undefined} : {})
    }

    if (!val || is_full_range) dispatch(set_selected_filter(undefined));
    if (!in_range) dispatch(set_selected(undefined));
    dispatch(setUrl([params, navigate, right_path, history ]));
};