import { curry, compose as C, mergeRight, fromPairs, map as m, sort, descend, indexOf, concat, slice,
  chain, repeat, filter as f, path, toPairs, juxt, max, divide, groupBy as g, prop as p, includes,
  keys, join, reverse, __, length as len,head, last,
  identity as id,range, propEq, tap, apply} from "ramda";
import { r } from "../helpers";
import { useSelector } from "react-redux";

const Dot = (x) => <span class="dot" style={{background:/* category_colors(x) || */ "black"}}></span>; //TO-DO ? kas lisada värvid

const Column = ({year,yearsrange,data}) => {
  const opacity = (yearsrange.low <= Number(year) && Number(year) <= yearsrange.high) ? 1 : 0.1
  return <div className="column" style={{opacity}}>{m(x => <Dot/>,data)}</div>
}

const dots = curry(({n_as_dot,sorting_order},xs) => C(
/*   mergeRight(fromPairs(m(x => [x,[]],range(2000,2025)))), //TO-DO replace range with query
  m(C(
    //sort(descend(indexOf(__,sorting_order))),
    ({need,data,filled}) => concat(
      filled,
      C(m(head),slice(0,need-filled.length),sort(descend(last)),toPairs)(data)),
    ([need,data]) => ({
      need,
      data:m(last,data),
      filled:C(chain(([key,[n]]) => repeat(key,n)),f(path([1,0])),toPairs)(data)
    }),
    juxt([
      C(max(1),r(0),divide(__,n_as_dot),len),
      C(
        m(C(juxt([C(Math.floor,divide(__,n_as_dot)),x => x%n_as_dot]),len)),
        g(p("color")))
      ]))),
  g(p("year")),
  chain(x => C(
    m(a => ({...x,year:a})),
    apply(range),
    juxt([p("start"),x => x.end || x.start])
  )(x)), */
  f(x => (x.start || x.end) &&  x.color),
)(xs))

export const TimelineDots = ({xs}) => {
  const yearsrange = useSelector(path(["ui","yearsrange"]));
  const dots_conf = useSelector(path(["ui","dots_conf"]));
  const nodes = useSelector(path(["ui","nodes"]));
  return <div className="dotsGraph">{
/*     m(C(
//    (year) => <Column year={year} yearrange={yearsrange}/>,
    reverse,
    m(C(toPairs,dots(dots_conf))),
    f(propEq("group","projekt")),
    )(nodes)) */
    }</div>;
}

