import React from 'react';
import { useField } from 'formik';

export const EditableText = ({className, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const onInput = e => { helpers.setValue(e.currentTarget.innerText); };
    const onBlur = () => { helpers.setTouched(true); };
    return <span
        className={className || "textArea"}
        suppressContentEditableWarning
        onInput={onInput}
        onBlur={onBlur}
        contentEditable>{meta.initialValue || "See on artikli sisu."}</span>;
};
