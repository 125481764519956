import { useSelector } from "react-redux";
import { path } from "ramda";
import "./privacy.css"
import { Part } from "./part";

export const Privacy = () => {
    const language = useSelector(path(["ui","language"]));
//    const reopenConsent = () => {
//        resetCookieConsentValue();
//      };
//    const isConsent = getCookieConsentValue();
    return <div className="privacy_page">
        <Part part_id={language === "EN" ? 89 : 88} />
        {/* <button onClick={reopenConsent}>Praegu on väärtus:{isConsent}</button> */}
    </ div>}
    