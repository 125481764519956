import * as React from "react";
const Linkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Zm8.414-26.414A2 2 0 0 0 25 9H11a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V11a2 2 0 0 0-.586-1.414ZM24.5 19.2v5.3h-2.79v-4.93a1.4 1.4 0 0 0-1.4-1.4c-.77 0-1.39.63-1.39 1.4v4.93h-2.79v-8.37h2.79v1.11c.48-.78 1.47-1.3 2.32-1.3a3.26 3.26 0 0 1 3.26 3.26Zm-10.432-5.132a1.686 1.686 0 0 1-2.878-1.188 1.69 1.69 0 0 1 1.69-1.69 1.686 1.686 0 0 1 1.188 2.878Zm.202 2.062v8.37H11.5v-8.37h2.77Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Linkedin;