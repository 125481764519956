import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createParams } from "../helpers";
import { path, replace, toLower } from "ramda";
import { setSelected, setSelectedFilter } from "../../app/set_actions";

export const AppLink = ({children,text,to,selected,history,type, ...props }) => {
  const dispatch = useDispatch();
  const url = useSelector(path(["ui","url"]));
  const current_selected = useSelector(path(["ui","selected"]));
  const navigate = useNavigate();
  const language = useSelector(path(["ui","language"]));
  const same_lang_to = replace(/^\//,language !== "ET" ? `/${toLower(language)}/` : "/",to);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch((type ? setSelectedFilter : setSelected)([selected || current_selected,navigate,same_lang_to,history,type]));
  }
  
  return <Link 
    to={`${same_lang_to}?${createParams(url.params)}`} 
    onClick={handleClick} {...props}>
      {text || children}
  </Link>
};