import React from 'react';
import { Part } from './part';
import {Fields, Services } from './swiper';
import { map as m, path } from 'ramda';
import "./about.css";
import { useLangQuery } from '../app/api';
import { useSelector } from 'react-redux';

export const about_ids = {
    ET:{
        intro: [2,3,4],
        values: [8,9,10],
        fields: [23,24,25,26],
        services: [[11,12,13],[14,15,16],[17,18,19]]
    },
    EN:{
        intro:[64,65,66],
        values:[67,68,69],
        fields: [76,77,78,79],
        services: [[70,71,20],[72,73,21],[74,75,22]]
    }
}

export const About = ({intro,values,fields,services}) => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    return <>
        <div></div>
        <h1 className="about-title">{translation["about"]}</h1>
        <div className="three-col introduction">
            {m(x => <Part part_id={x} key={"intro_"+x}/>,intro)}
        </div>
        <div id="pohimotted" className="three-col principles">
            <div className="colored-background">
                {m(([x,y,cls]) => <div className={cls} style={{top:y+"%", left: x+"%"}}></div>,
                [[15,0,"outer"],[50,110,"outer"],[95,20,"outer"],
                [15,0,"inner"],[50,110,"inner"],[95,20,"inner"]])}
            </div>
            {m(x => <Part part_id={x} key={"intro_"+x}/>,values)}
        </div>
        <Fields id="valdkonnad" ids={fields} className="single-col"/>
        <Services id="teenused" ids={services} className="single-col"/>
    </>
}