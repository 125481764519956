
import { useDispatch, useSelector } from "react-redux";
import { Part } from "../../pages/part";
import { Link, Socials } from "../navigation/Navigation";
import "./footer.css";
import { path } from "ramda";
import { AppLink } from "../navigation/applink";
import { useLangQuery } from "../../app/api";
import { menu_open, show_info } from "../../app/ui_state";

export const Footer = () => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const dispatch = useDispatch();

    return <footer>
        <div>
            <section>
                <p>SA Mõttekoda Praxis</p>
                <p>praxis@praxis.ee</p>
                <p>reg nr 90005952</p>
            </section>
            <section>
                <Link text={translation["home"]} to="/"></Link>
                <Link text={translation["about"]} to="/praxisest#"></Link>
                <Link text={translation["jobs"]} to="/kandideeri"></Link>
                <button onClick={(x) => {
                    x.stopPropagation();
                    dispatch(menu_open(true));
                    dispatch(show_info(true));
                }}>{translation["contacts"]}</button>
            </section>
            <section>
                <AppLink text={translation["works"]} to={`/tood`}></AppLink>
                <AppLink text={translation["people"]} to={`/inimesed`}></AppLink>
                <AppLink text={translation["topics"]} to={`/teemad`}></AppLink>
                <Link text={translation["privacy"]} to="/privaatsus"></Link>
            </section>
            <section>
                <Link text={translation["news"]} to="uudised"></Link>
                <Link text={translation["newsletter"]} to="/uudised?liitu=1"></Link>
                <Part part_id={30} className={"socials"} components={{a: Socials}} />
            </section>
        </div>
    </footer>
}
