import { compose as C, __, head, map as m, path, prop as p, split, toLower, replace, join} from 'ramda';
import React, { createElement, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AppLink } from './applink';
import { menu_open,show_info } from '../../app/ui_state';
import './navigation.css';
import { Part } from '../../pages/part';
import Instagram from '../../instagram';
import Facebook from '../../facebook';
import Twitter from '../../twitter';
import Linkedin from '../../linkedin';
import { useLangQuery } from '../../app/api';


const nav_with_close = dispatch => x => {
    x.stopPropagation(); 
    dispatch(menu_open(false));
    dispatch(show_info(false));
}

export const Link = ({to,id,text}) => {
    const language = useSelector(path(["ui","language"]));
    const same_lang_to = replace(/^\//,language !== "ET" ? `/${toLower(language)}/` : "/",to);
    const dispatch = useDispatch();
    return createElement(/teemad|inimesed|tood/.test(same_lang_to) ? AppLink : /#/.test(same_lang_to) ? HashLink : NavLink,
        {smooth:"true", onClick:nav_with_close(dispatch), to:same_lang_to,id},text)
}

const Locations =({href,children}) => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const texts = split(",",head(children));
    return <span className="location" key={"location_"+head(texts)}>
        {m(x => /^[+()0-9-\s]*$/.test(x) ? <a key={x} href={"tel:"+x}>{x}</a> : <span key={x}>{x}</span>,texts)}
        <br/><a className="directions" href={href} rel="noreferrer" target="_blank">{translation["directions"]}</a>
    </span>
}

export const Socials =({href,children}) => {
    const socials = { instagram: Instagram,  facebook: Facebook, linkedin: Linkedin, twitter: Twitter}
    const Element = C(p(__,socials),toLower,head)(children)
    return <a href={href}>{!!Element ?<Element className="social">{children}</Element> : children}</a>;
}
const Navigation = () => {
    const dispatch = useDispatch();
    const opened = useSelector(path(["ui","menu_open"]));
    const selected = useSelector(path(["ui","selected"]));
    const showing_info = useSelector(path(["ui","show_info"]));
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    const [opened_block, open_block] = useState(null);
    
    const nav_data = [
        {text: "home",to: "/",},
        {text: "about_group",to: "/praxisest#",children: [
            { text: "about", to: "/praxisest#" },
            { text: "principles", to: "/praxisest#pohimotted" },
            { text: "fields", to: "/praxisest#valdkonnad" },
            { text: "services", to: "/praxisest#teenused" }],
        },
        {text: "discover",to: "/tood",isAppLink: true,children: [
            { text: "works", to: "/tood", isAppLink: true },
            { text: "people", to: "/inimesed", isAppLink: true },
            { text: "topics", to: "/teemad", isAppLink: true }
        ],
        },
        {text: "read",to: "/uudised",children: [
            { text: "news", to: "/uudised" },
            { text: "newsletter", to: "/uudised?liitu=1" }],
        },
        {text: "jobs",to: "kandideeri"},
    ];
    
    const renderNav = (items, translation,sub) =>
        <ul>{items.map(({ text, to, children, isAppLink },n) => (
            <li onClick={(x) => {
                if(children) {
                    x.stopPropagation();
                    open_block(n);
                }
            }} 
                key={text} 
                className={ join(" ",[sub ? "subitem" : children ? "menu-accordion" : "",opened_block === n ? "active" : ""])}>
                {children ? (<p>{translation[text]}</p>) : 
                isAppLink ? (<AppLink text={translation[text]} to={to} onClick={nav_with_close(dispatch)}/>) : (
                    <Link text={translation[text]} to={to} onClick={nav_with_close(dispatch)}/>)}
                {children && renderNav(children, translation,1)}
            </li>
    ))}</ul>; 


    return <div className={"sidepanel" + (opened ? " opened" : "") + (showing_info ? " info" : "")} >
        <div className="overlay" onClick={nav_with_close(dispatch)}></div>
        <nav onClick={(x) =>{x.stopPropagation(); dispatch(menu_open(false));dispatch(show_info(false));}}>
            {renderNav(nav_data, translation)}
        </nav>
        <div className="information" onClick={() =>{dispatch(menu_open(false));dispatch(show_info(false));}}>
            <h2>SA Mõttekoda Praxis</h2>
            <a href="mailto:praxis@praxis.ee">praxis@praxis.ee</a>
            <Part part_id={30} components={{a: Socials}} />
            <Part part_id={language === "EN" ? 80 : 32} className={"locations"} components={{a: Locations}}/>
            <div className="requisites">
                <div className='two_in_row'>
                    <div>
                        <p className='label'>REG NR</p>
                        <p>90005952</p>
                    </div>
                    <div>
                        <p className='label'>{translation["vat"]}</p>
                        <p>EE100951538</p>
                    </div>
                </div>
                <div>
                    <p className='label'>{translation["bank"]}</p>
                    <p>EE847700771007487052</p>
                </div>
                <div>
                    <p className='label'>BIC / SWIFT</p>
                    <p>LHVBEE22</p>
                </div>
            </div>
        </div>
    </div>;
}

export default Navigation;