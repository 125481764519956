import { useRef, useState } from "react";
import { supabase } from "./api";
import "./auth.css";

const Auth = () => {
    const [helperText, setHelperText] = useState({ error: null, text: null });
    const emailRef = useRef();
    const passwordRef = useRef();

    const handleLogin = async (type) => {
        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;
        const { user, error } =  /* type === "LOGIN"
                ?  */await supabase.auth.signInWithPassword({ email, password })
               /*  : await supabase.auth.signUp({ email, password }); */
        if (error) {
            setHelperText({ error: true, text: error.message });
        } else if (!user && !error) {
            setHelperText({ error: false,text: "Saatsin su e-mailile kinnituslingi."});
        }
    };

    const forgotPassword = async (e) => {
        e.preventDefault();
        const email = prompt("Palun sisesta oma e-mail:");
        if (email === null || email === "") {
            setHelperText({ error: true, text: "Ilma e-mailita ei saa parooli taastada." });
        } else {
            let { error } = await supabase.auth.api.resetPasswordForEmail(email);
            if (error) {console.error("Error: ", error.message);} 
            else {setHelperText({error: false, text: "Su e-mailile on saadetud juhend parooli taastamiseks.",});}
        }
    };

    return (
        <div className="loginform">
            <label htmlFor={"email"}><span>*</span>E-MAIL:</label>
            <input type={"email"} name={"email"} ref={emailRef} required/>
            <label htmlFor={"password"}><span>*</span>PAROOL:</label>
            <input type={"password"} name={"password"} ref={passwordRef} required/>
{/*             <span>
                <button type="submit" onClick={() =>handleLogin("REGISTER").catch(console.error)}>
                    Sign Up
                </button>
            </span> */}
            <button type="submit" onClick={() => handleLogin("LOGIN")}>Logi sisse</button>
            <span className="forgotpass" onClick={forgotPassword}>Unustasid parooli?</span>
            {!!helperText.text && (<div>{helperText.text}</div>)}
        </div>
    );
};

export default Auth;