import { compose as C, map as m, prop as p, __, uniqBy} from "ramda";

export const CategoryIcons = ({data}) => {
    return <div className="categories">{C( 
        m( ({icon,id}) => <img 
            key={`icon_${id}`} 
            src={`/uploads/ui/${icon}`} 
            decoding="async" 
            loading="lazy" 
            className="category" /> ),
        uniqBy(p("icon")),
        m(p("categories")),
        p("Projekt_Kategooria")
    )(data)}</div> 
}