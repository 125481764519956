import * as React from "react";
const Twitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Zm8.413-27-6.7 8.045L27 28h-5.36l-4.908-7.377L10.588 28H9l7.027-8.437L9 9h5.36l4.647 6.986L24.826 9h1.587Zm-9.587 9.605.712 1.051 4.85 7.166h2.438l-5.943-8.78-.712-1.053-4.572-6.754H11.16l5.666 8.37Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Twitter;