
import { voronoi,len_and_curve,size_and_pos,node_r, node_opacity,node_fill, grow,
  line_grow,line_shrink, shrink,transitioned,highlight_one,remove_highlights
} from "./render_fns";
import {map as m, prop as p, filter as f,path, indexBy, compose as C, juxt, uniq, join, __, range, toLower, nth
} from "ramda"
import { useEffect,useRef } from "react";
import { useAvatarsQuery, useCategoriesQuery, useLangQuery, } from "../../app/api";
import { add_size,  isSafari } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import { select } from "d3";
import "./graph.css";
import { setSelected } from "../../app/set_actions";
import { useNavigate } from "react-router-dom";

const ImagePattern = ({id,img}) => (
  <pattern height={1} width={1} id={id} key={id} patternContentUnits="objectBoundingBox">
    <image height={1} width={1} xlinkHref={img}></image>
  </pattern>
)

export const NetworkGraph = ({style,selection_off}) => {
  const language = useSelector(path(["ui","language"]));
  const page = nth(language !== "ET" ? 2 : 1,window.location.pathname.split("/"));
  const {data:translation} = useLangQuery(language);
  const ref = useRef();
  const nodes_data = useSelector(path(["ui","nodes"]));
  const links_data = useSelector(path(["ui","links"]))
  const size = useSelector(path(["ui","svg_side"]))
  const duration = useSelector(path(["ui","duration"]))
  const selected = useSelector(path(["ui","selected"]))
  const {data:patterns} = useAvatarsQuery();
  const {data:categories} = useCategoriesQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const osakonnad = categories ? C(
    uniq,m(juxt([p("osakond_id"),({icon}) => `/uploads/ui/${icon}`]))
  )(categories) : [];

  useEffect(() => {    
    const svg = select(ref.current);
    svg.attr("width", size).attr("height", size); 
    const category_map = categories ? indexBy(C(x => x.ui_id),categories) : []
    const draw_line = x => {x.call(len_and_curve(d => d.totalLength))
      .style("fill", "transparent")
      .style("stroke",d => 
          d[d?.target?.group === "inimene" ? "source" : "target"]?.color || 
          category_map[d?.source?.ui_id]?.color || 
          "black")
      .style("stroke-width",d => 2.5 + (d.source?.power ? d.source?.power*0.5 : 0.5))
      .style("stroke-linecap","round")
      .style("stroke-opacity",d => 0)
    }
    const draw_node = x => {x.call(size_and_pos(node_r(selected)))
      .attr("patternContentUnits", "objectBoundingBox")
      .style("opacity", node_opacity(selected))
      .style("stroke-width", 2)
      .style("stroke", x => (x.group === "inimene" && x.active) ? "black" : x.group === "projekt" ? node_fill(category_map)(x) :"transparent")
      .style("fill", d => (!d.pilt && d.group === "inimene") ? "url(#placeholder)" : (d.group === "projekt" && language === "EN" && !d.projectname_eng) ? "#eee" : node_fill(category_map)(d))
    }

    const links = svg.select('#links').selectAll("path")
      .data(links_data, d => d.source?.ui_id+"_"+d.target?.ui_id)
      .join(x => x.append("path").call(line_grow(draw_line,2*duration,duration/2)),
            x => x.call(transitioned(len_and_curve(d => 0),duration,duration)),
            x => x.call(line_shrink(len_and_curve(d => d.totalLength),duration)))   

    const nodes = svg.select('#nodes').selectAll("circle")
      .data(nodes_data, d => d.ui_id)
      .join(x => x.append("circle").call(grow(draw_node,node_r(selected),duration,duration)),
            x => x.call(transitioned(size_and_pos(node_r(selected)),duration,duration)),
            x => x.call(shrink(draw_node),duration))
    
    if (!selection_off && selected) {
      highlight_one(nodes,links,links_data,selected,category_map);
    }

    const hover_cells = size ? voronoi(nodes_data,size,size) : [];

    const tooltip = svg.select('#tooltip')
    const tt_title = svg.select('#tooltip-title')
    const tt_body = svg.select('#tooltip-years')
    const tt_extra = svg.select('#tooltip-job')
    const tt_info = svg.select("#tooltip-info")

    const forceRedraw = (element) => {
      const parent = element.parentNode;
      const nextSibling = element.nextSibling;
      parent.removeChild(element);
      parent.insertBefore(element, nextSibling);
    };

    const add_tooltip = (e,d) => {
      const body_text = d.group === "inimene" ? (
        d.active ? d[language === "EN" ? "job_eng" : "job"] : 
        d.Tootamised.length ? translation["tooltip_worked_in"] : translation["co_author"]) : 1; 

      const field_path = C(
        join(" > "),
        m(C(p(language === "EN" ? "name_eng" : "name"),p(__,category_map)))
      )(d.path ? d.path.map(([c]) => `c_${c}`) : [d.ui_id]);

      const title_text = d.group === "projekt" ? 
        (language === "EN" ? (d.projectname_eng ? d.projectname_eng : "EST only: " + d.name) : d.name) : 
      d.group === "field" ? field_path : 
      (d.firstname + (d.lastname ? (" " + d.lastname) : ""));

      const explain_duration = x => x.start === x.end ? x.start + " " : x.start + " - "+ (x.end || "...");
      const explain_working = xs => !xs.length ? null : 
        join(" , ",xs.map(x => x.end !== null ? explain_duration(x) : translation["tooltip_in_praxis"] + " " + x.start + " " + translation["tooltip_from"] ));

      const explain_text = 
        d.group === "projekt" ?  explain_duration(d) : 
        d.group === "inimene" ? explain_working(d.Tootamised) :
          null;

      tooltip
        .style("visibility", "visible")
        .style("top", isSafari(d.y)+d.vy+(size/2)+(isSafari(d.y) > 50 ? -20 : 20) + "px")
        .style("left", isSafari(d.x)+d.vx+(size/2)+(isSafari(d.x) > 50 ? -20 : 20) + "px")
        .style("transform", `translate(${isSafari(d.x) > 50 ? "-100%" : 0},${isSafari(d.y) > 50 ? "-100%" : 0})`)

        tt_title
          .text(title_text)
          .style("background",   d.group !== "inimene" ? (d.color || category_map[d.ui_id]?.color) : d.active ? "black" :"none")
          .style("color",       (d.group !== "inimene" || d.active) ? "white" : "black")
          .style("border-radius",d.group === "field" ? "6px" : d.group === "inimene" ? 0 : "6px 6px 0 0")
          .style("display", "block")
          .style("padding",     (d.active || d.group === "projekt" || d.group === "field") ? "5px" : "5px 5px 0")
        tt_info
          .style("padding", d.group === "field" ? "0" : "0 5px 5px")
        tt_body
          .text(d.active ? body_text : explain_text)
          .style("display", d.group !== "field" ? "inline" : "none")
        tt_extra
          .text(d.active ? explain_text : body_text)
          .style("display", d.group !== "inimene" ? "none" :  "inline");

      forceRedraw(tooltip.node());
    }

    const remove_tooltip = (e,d) => { tooltip.style("visibility", "hidden")}
    
    if (!selected) remove_highlights(nodes,links,selected);
    
    const only_workers = d => d.group !== "inimene" || d.active;

    svg.select("defs")
      .selectAll("clipPath")
      .data(nodes_data)
      .join(
        x => x.append("clipPath")
          .attr("id", d => "clip-" + d.ui_id)  
          .append("circle")
          .attr("cx", d => d.x)
          .attr("cy", d => d.y)
          .attr("r", 70),
        x => x.attr("id", d => "clip-" + d.ui_id)  
          .select("circle")
          .attr("cx", d => d.x) 
          .attr("cy", d => d.y)
          .attr("r", 70),
        exit => exit.remove()
      );

    svg.select('#hoverareas')
      .selectAll('.voronoi')
      .data(nodes_data,d => d.ui_id)
      .join(
        x => x.append("path")
          .attr("class", "voronoi")
          .attr("d", (d, i) => hover_cells.renderCell(i))
          .style("fill", "transparent")
          .style("cursor", x => (x.type === "P" || x.type === "O1") ? "pointer" : "normal")
          .attr("clip-path", (d) => `url(#clip-${d.ui_id})`),
        x => x.attr("d", (d, i) => hover_cells.renderCell(i))
          .attr("clip-path", (d) => `url(#clip-${d.ui_id})`),
        x => x.remove() 
      )
      .attr('class', d => only_workers(d) ? "voronoi is_link" : "voronoi")
      .on('mouseover', (x,d) => {
        add_tooltip(x,d);
        if (!selected) {
          highlight_one(nodes,links,links_data,d,category_map)
        }})
      .on('mouseout', (x,d) => {
        remove_tooltip();
        if (!selected) {remove_highlights(nodes,links,selected)
      }})
      .on('click', (x,d) => {
        remove_tooltip();
        const pages = ({"inimene": "inimesed/","field": "teemad/","projekt": "tood/"})
        const to = (d.group === "inimene" && !d.active) ? `/${page}/` : pages[d.group];
        const slug = only_workers(d) ? (d.slug || category_map[d.ui_id].slug) : ""; 
        const lang = language !== "ET" ? `/${toLower(language)}/` : "/";
        dispatch(setSelected([
          (selected !== undefined && selected.ui_id === d.ui_id) ? "remove" : d,
          navigate,
          `${lang}${to}${slug}`
        ]));
      });
      svg.select('#bg')
        .attr("x",-size*1.1 / 2)
        .attr("y",-size*1.1 / 2)
        .attr("width",size*1.1)
        .attr("height",size*1.1)
        .style("cursor","pointer")
        .attr("fill","transparent")
        .on("click", () => {
          dispatch(setSelected(["remove", navigate]));
      })
  },[nodes_data,links_data,selected,duration,categories,selection_off,dispatch,navigate,page,size,language,translation])  

  const visual = (size && nodes_data.length) ? <>
    <svg ref={ref} id="graph" style={style} width={size} height={size}
      viewBox={[-size*1.1 / 2, -size*1.1 / 2, size*1.1, size*1.1]}> 
        <defs></defs>
        <rect id="bg"></rect>
        <g>
          {patterns ? <g id="patterns">
            <ImagePattern id="placeholder" img={"/uploads/ui/placeholder.svg"}/>
            {m(x => <ImagePattern key={x.slug} id={x.slug} img={add_size(120,x.pilt)} />,f(p("pilt"),patterns))}
            {m(([key,url]) => <ImagePattern key={key} id={key} img={url}/>,osakonnad)}</g> : ""}
          <g id="links"></g>
          <g id="nodes"></g>
          <g id="hoverareas"></g>
        </g>
        <foreignObject x={size ? -size/2 : 0} y={size ? -size/2 : 0} width={size} height={size} pointerEvents="none">
          <div id="tooltip" style={{"visibility":"hidden"}}>
              <div id="tooltip-title"></div>
              <div id="tooltip-info">
                <p id="tooltip-job"></p>
                <p id="tooltip-years"></p>
              </div>
          </div>
        </foreignObject>
    </svg>
  </> : 
  <div className="loader">
    {m(x => <div key={"particle"+x} className="particle"></div>, range(0,12))}
  </div>;

  return visual;
}